import { createRouter, createWebHistory } from 'vue-router';
import { trackPageView } from '@/services/r42';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () =>
            {
                return import( /* webpackChunkName: "landing" */ '@/views/Landing' );
            }
        },

        {
            path: '/uitleg',
            component: () =>
            {
                return import( /* webpackChunkName: "explain" */ '@/views/Explain' );
            }
        },

        {
            path: '/game',
            component: () =>
            {
                return import( /* webpackChunkName: "game" */ '@/views/Game' );
            }
        },

        {
            path: '/score',
            component: () =>
            {
                return import( /* webpackChunkName: "score" */ '@/views/Score' );
            }
        }
    ]
});

router.beforeEach( ( to, from, next ) =>
{
    trackPageView( to.path, to.meta.pageType );

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default router;
