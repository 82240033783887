const arrayShuffle = ( array ) =>
{
    for( let i = array.length; i; i-- )
    {
        let j = Math.floor( Math.random() * i );
        let saveValue = array[ i - 1 ];
        array.splice( i - 1, 1, array[ j ] );
        array.splice( j, 1, saveValue );
    }
};

export default {
    namespaced: true,

    state: {
        cases: [
            { index: 1, opened: false, value: 0.01 },
            { index: 2, opened: false, value: 0.5 },
            { index: 3, opened: false, value: 10.5 },
            { index: 4, opened: false, value: 20 },
            { index: 5, opened: false, value: 100 },
            { index: 6, opened: false, value: 1000 },
            { index: 7, opened: false, value: 5000 },
            { index: 8, opened: false, value: 20000 },
            { index: 9, opened: false, value: 40000 },
            { index: 10, opened: false, value: 100000 },
            { index: 11, opened: false, value: 500000 },
            { index: 12, opened: false, value: 1000000 },
            { index: 13, opened: false, value: 5000000 },
            { index: 14, opened: false, value: -10 },
            { index: 15, opened: false, value: -2500 },
            { index: 16, opened: false, value: -50000 },
            { index: 17, opened: false, value: -250000 },
            { index: 18, opened: false, value: -2000000 },
            { index: 19, opened: false, value: 0 },
            { index: 20, opened: false, value: 0 },
            { index: 21, opened: false, value: 0 },
            { index: 22, opened: false, value: 0 },
            { index: 23, opened: false, value: 0 },
            { index: 24, opened: false, value: 0 },
            { index: 25, opened: false, value: 0 },
            { index: 26, opened: false, value: 0 }
        ],

        order: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25 ],

        maxOpened: 10
    },

    getters: {
        finalScore: state =>
        {
            let openedCases = state.cases.filter( item => item.opened === true );
            let score = 0;
            openedCases.forEach( item =>
            {
                score += item.value;
            });

            return score;
        },

        maxOpened: state => state.maxOpened,
        totalOpened: state => state.cases.filter( item => item.opened === true ).length,

        cases: state => state.cases
    },

    mutations: {
        reset: state =>
        {
            state.cases.forEach( item => item.opened = false );
            arrayShuffle( state.cases );
        },

        open: ( state, payload ) =>
        {
            let selected = state.cases.find( item => item.index === payload );
            selected.opened = true;
        }
    },

    actions: {
        reset: context =>
        {
            context.commit( 'reset' );
        },

        open: ( context, payload ) =>
        {
            // payload = case value
            context.commit( 'open', payload );
        }
    }
};
