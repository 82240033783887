function trackNavigation( path, type )
{
    console.log( 'navigation event: ' + path );
    if( window.gdl && window.gdl.stapname )
    {
        window.gdl( 'event', {
            'type': 'navigation',
            'navigation': {
                'productBrand': 'npl',
                'platformSiteType': 'campaign',
                'pageType': type,
                'url_path': path
            },
            'data': {
                'appType': 'spi',
                'campaignId': 'mjj_kofferspel',
                'campaignName': 'mjj_kofferspel',
                'campaignNumber': '',
                'campaignProposition': '',
                'platformBrand': 'npl',
                'platformType': 'ttp',
                'platformSiteType': 'campaign',
                'platformTemplate': ''
            }
        });
    }
}

function trackPageView( path )
{
    console.log( 'pageview event: ' + path );
    if( window.gdl && window.gdl.stapname )
    {
        window.gdl( 'event', {
            type: 'page',
            type_event: 'pageview',
            data: {
                label: path
            }
        });
    }
    if( window.gdl )
    {
        window.gdl.stapname = path;
    }
}

function trackEvent( el, type )
{
    let ipType = el.getAttribute( 'type' ) ? el.getAttribute( 'type' ) : 'click';
    ipType = ipType === 'email' ? 'text' : ipType;
    try
    {
        window.gdl( 'event', {
            type: 'interaction',
            interaction: {
                identification: {
                    unique_identifier_type: 'data-gdl-type', // waar unIdT de id type is â€“ liefst data-gdl-type of zo niet dan id of name of class
                    unique_identifier: el.dataset.gdlType, // waar unId de element id is â€“ liefst worden de juiste data-gdl-type attributes gebruikt per element
                    title: el.dataset.gdlType,
                    area: '',
                    type: ipType,
                    parent_form: {
                        unique_identifier_type: 'id',
                        unique_identifier: 'no form id', // ik zie geen â€˜formâ€™ tag dus ik neem aan dat er geen id hier gegeven kan worden
                        title: 'no form title', // ik zie geen â€˜formâ€™ tag dus ik neem aan dat er geen title hier gegeven kan worden
                        area: '',
                        type: 'form',
                        parent_form: 'no_parent_form'
                    },
                    element_source: window.gdl.stapname === '' ? window.location.pathname : window.gdl.stapname
                },
                action: type // dus focus of click etc
            },
            element_target: typeof el.getAttribute( 'href' ) !== 'undefined' ? el.getAttribute( 'href' ) : ''
        });
        console.log( 'interaction event: ' + type + ', triggered for ' + el.dataset.gdlType, ipType );
        return;
    }
    catch( e )
    {
        console.log( 'interaction event: ' + type + ', failed to trigger for ' + el.dataset.gdlType + ': ' + e.message );
    }
}

export {
    trackPageView,
    trackEvent,
    trackNavigation
};