import { createStore } from 'vuex';

import game from './modules/game';

export default createStore({

    modules: {
        game
    },

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }
});
