<template>
    <ynk-layout>
        <router-view v-slot="{ Component, route }" >
            <transition mode="out-in" name="fade-down" >
                <component :is="Component" ref="currentView" :key="route.path" />
            </transition>
        </router-view>
    </ynk-layout>
</template>

<style lang="scss">
    @import './scss/app.scss';

    .fade-down-enter-active {
        transition: all 0.3s ease-out;
    }

    .fade-down-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .fade-down-enter-from,
    .fade-down-leave-to {
        transform: translateY(20px);
        opacity: 0;
    }

    .game-view{
        transition: opacity 0.3s ease-out !important;
    }
</style>

<script>
    import Main from '@/layouts/Main';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();
        },

        methods: {
            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            }
        }
    };
</script>
